<template>
	<v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" @input="$emit('close')">
		<v-card>
			<v-toolbar color="primary" dark>
				<v-toolbar-title>{{ model.name }}</v-toolbar-title>
				<w-spacer />
				<v-toolbar-items>
					<w-btn icon="zoom_in" mini @click="zoomIn">{{ $t('actions.zoom_in') }}</w-btn>
					<w-btn icon="zoom_out" mini @click="zoomOut">{{ $t('actions.zoom_out') }}</w-btn>
					<w-btn v-if="showValidationButton" icon="check" :round="false" @click="signDocument()">{{ $t('actions.validate') }}</w-btn>
					<w-btn icon="close" mini @click="closeFilePreview(false)">{{ $t('actions.close') }}</w-btn>
				</v-toolbar-items>
			</v-toolbar>
			<div class="pdf-viewer">
				<PDFPreview
					v-if="pdf"
					v-show="$vuetify.breakpoint.mdAndUp"
					class="pdf-viewer__preview"
					:pages="pdfRenderedThumbnails"
					:scale="scale"
					:current-page="pageNumber"
					:total-items="totalPages"
					:is-preview-enabled="$vuetify.breakpoint.mdAndUp"
					@page-focus="setPageNumber"
				/>
				<PDFDocument
					v-if="pdf"
					class="pdf-viewer__document"
					:class="{ 'preview-enabled': $vuetify.breakpoint.mdAndUp }"
					:pages="pdfRenderedPages"
					:scale="scale"
					:current-page="pageNumber"
					:total-items="totalPages"
					:is-preview-enabled="$vuetify.breakpoint.mdAndUp"
					@page-focus="setPageNumber($event)"
					@pages-fetch="fetchPages()"
				/>
				<v-card
					v-if="loading"
					color="primary"
					dark
					style="left: 50%; max-width: 100vw; position: fixed; top: 50%; transform: translate(-50%, -50%); z-index: 10"
				>
					<v-card-text>
						{{ $t('signature.documents.pdf_loading') }}
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</div>
			<v-snackbar bottom color="info" right :timeout="0" :value="true">
				<v-layout align-center row justify-center>
					<v-icon class="mr-2">info</v-icon>
					<v-flex v-t="'signature.documents.preview_info'" shrink />
				</v-layout>
			</v-snackbar>
		</v-card>
	</v-dialog>
</template>

<script>
import SignatureModuleGuard from '@/mixins/ModulesGuards/Signature/SignatureModuleGuard'
import DocumentsManagerService from '@/services/Documents/DocumentsManagerService'
import * as PDFJs from 'pdfjs-dist/webpack'
import * as Files from '@/helpers/files'
import { mapState } from "vuex";

const MAX_RENDERED_PAGES = 10
const ZOOM_STEP = 0.25

export default {
	name: 'FilePreview',
	components: {
		PDFDocument: () => ({
			component: import('@/components/Documents/Signature/Preview/FilePreview/PDFDocument')
		}),
		PDFPreview: () => ({
			component: import('@/components/Documents/Signature/Preview/FilePreview/PDFPreview')
		})
	},
	mixins: [SignatureModuleGuard],
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			cursor: 0,
			dialog: false,
			loading: true,
			pageNumber: 1,
			pdf: null,
			pdfRenderedPages: [],
			pdfRenderedThumbnails: [],
			scale: 1,
			totalPages: 0,
			OAUTH_PROVIDER_JESIGNEXPERT: 'jesignexpert',
			DEFAULT_AREA_WIDTH: 200,
			DEFAULT_AREA_HEIGHT: 61
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			},
			...mapState({
				pauseKeyboardListening: state => state.pauseKeyboardListening
			}),
		},

		showValidationButton: function () {
			return this.getSignatureAreas().length > 0
		}
	},
	created: function () {
		this.initialize()
	},
	mounted: function () {
		this.dialog = true
		this.$store.dispatch('setPauseKeyboardListening', true)
		window.addEventListener('resize', this.handleResize)
	},
	destroyed: function () {
		this.$store.dispatch('setPauseKeyboardListening', false)
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
		closeFilePreview: function (signatureValidated = true) {
			this.dialog = false
			this.service.deleteSignatureAreas()
			this.$emit('close', signatureValidated)
		},
		handleResize: function () {
			if (this.$vuetify.breakpoint.smAndDown) {
				this.closeFilePreview(false)
				this.appEventBus.emit(this.appEvents.SNACKBAR_WARNING, this.$t('signature.documents.warning.resize_small_screen'))
			}
		},
		initialize: function () {
			this.loading = true
			let fileUri = this.model.latestVersion.uri
			if ([Files.mimetypes.jpeg, Files.mimetypes.jpg, Files.mimetypes.png].includes(this.model.type)) {
				fileUri = fileUri + '&format=application/pdf'
			}
			return PDFJs.getDocument(fileUri)
				.promise.then(pdf => {
					this.setPDF(pdf)
					this.clearFetchedThumbnails()
					this.clearFetchedPages()
					this.cursor = 0
					return this.fetchPages()
				})
				.then(() => {
					return this.fetchThumbnails()
				})
				.finally(() => {
					this.loading = false
				})
		},
		fetchThumbnails: function () {
			const addedThumbnailsPromises = []
			for (let i = 1; i <= this.totalPages; i++) {
				addedThumbnailsPromises.push(
					this.pdf.getPage(i).then(thumbnail => {
						if (!this.pdfRenderedThumbnails.some(renderedThumbnail => renderedThumbnail._pageIndex == thumbnail._pageIndex)) {
							this.pdfRenderedThumbnails.push(thumbnail)
						}
					})
				)
			}
			return Promise.all(addedThumbnailsPromises)
		},
		fetchPages: function () {
			const minIndex = Math.max(this.cursor == 0 ? this.pageNumber - 1 - MAX_RENDERED_PAGES / 2 : this.cursor, 0)
			const maxIndex = Math.min(minIndex + MAX_RENDERED_PAGES, this.totalPages)
			this.cursor = maxIndex
			const addedPagesPromises = []
			for (let i = 1; i <= this.totalPages; i++) {
				if ((minIndex <= i && i <= maxIndex) || this.pageNumber == i) {
					addedPagesPromises.push(
						this.pdf.getPage(i).then(newPage => {
							if (!this.pdfRenderedPages.some(renderedPage => renderedPage._pageIndex == newPage._pageIndex)) {
								this.pdfRenderedPages.push(newPage)
							}
						})
					)
				}
			}
			return Promise.all(addedPagesPromises)
		},
		clearFetchedThumbnails: function () {
			this.pdfRenderedThumbnails.clear()
		},
		clearFetchedPages: function () {
			this.pdfRenderedPages.clear()
		},
		setPageNumber: function (pageNumber) {
			this.pageNumber = pageNumber
			this.fetchPages()
		},
		setPDF: function (pdf) {
			this.pdf = pdf
			this.totalPages = pdf.numPages
			this.clearFetchedPages()
			this.clearFetchedThumbnails()
			this.fetchPages().then(this.fetchThumbnails())
		},
		signDocument: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			this.signCurrentDocument()
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('signature.documents.signature_procedure_launched'))
					this.closeFilePreview(true)
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				})
		},
		signCurrentDocument: function () {
			const accountingFirmId = this.accountingFirmId
			const signatureAreas = this.service.getSignatureAreas()
			const versionId = this.model.latestVersion.id
			const folderId = this.model.folder_id
			const documentName = this.model.name
			const data = {
				meta: {
					accountingFirmId,
					signatureService: this.OAUTH_PROVIDER_JESIGNEXPERT,
					object: documentName
				},
				documents: [
					{
						id: versionId,
						folderId: folderId,
						metadata: {
							signFields: this.listSignatureAreasForSending()
						}
					}
				],
				signatories: signatureAreas
					.map(({ signatory }) => ({
						mobile: signatory.phone,
						email: signatory.email,
						order: signatory.order,
						firstname: signatory.firstname,
						lastname: signatory.lastname,
						id: signatory.id
					}))
					.filter((signatory, index, signatories) => {
						const signatoryIndex = signatories.findIndex(item => item.order === signatory.order)
						return signatoryIndex === index
					})
			}
			return this.service
				.createSignature(this.vendorId, this.model.id, data, { show_error: true })
				.then(documentsSignature => {
					return documentsSignature.forEach(documentSignature => {
						DocumentsManagerService.addOrUpdateNode(documentSignature)
					})
				})
				.catch(err => {
					return Promise.reject(err)
				})
		},

		listSignatureAreasForSending: function () {
			return this.service.getSignatureAreas().map(({ coords, id, page, signatory }) => {
				const { positionX, positionY } = this.convertCoordinatesForSending(id, coords)
				return {
					order: signatory.order,
					page,
					positionX,
					positionY
				}
			})
		},

		updateScale: function ({ scale, isOptimal = false }) {
			const roundedScale = Math.floor(scale * 100) / 100
			if (isOptimal) {
				this.optimalScale = roundedScale
			}
			this.scale = roundedScale
		},
		zoomIn: function () {
			if (this.scale) {
				const newScaleValue = this.scale + ZOOM_STEP
				this.updateScale({ scale: newScaleValue, isOptimal: false })
			}
		},
		zoomOut: function () {
			if (this.scale && this.scale > ZOOM_STEP) {
				const newScaleValue = this.scale - ZOOM_STEP
				this.updateScale({ scale: newScaleValue, isOptimal: false })
			}
		}
	}
}
</script>

<style scoped>
.header-item {
	margin: 0 2.5em;
}

.pdf-viewer .pdf-viewer__document,
.pdf-viewer .pdf-viewer__preview {
	top: 64px;
}

.pdf-viewer__preview {
	display: block;
	width: 15%;
	right: 85%;
}

.pdf-viewer__document {
	top: 64px;
	width: 100%;
	left: 0;
}

.pdf-viewer__document.preview-enabled {
	width: 85%;
	left: 15%;
}
</style>
